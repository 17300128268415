import { Environment } from '../constants/environment';
import { getCurrentEnv, storeDomain } from './environment';

// Storefront config
export type ShopifyStorefrontClientConfig = {
    storeDomain: string;
    storefrontToken: string;
    storefrontApiVersion: string;
};

const devShopifyStorefrontClientConfig: ShopifyStorefrontClientConfig = {
    storeDomain: 'https://velroq-dev.myshopify.com',
    storefrontToken: 'b6691d0a704501a9315a6f954f34e925',
    storefrontApiVersion: '2024-04',
};

const testShopifyStorefrontClientConfig: ShopifyStorefrontClientConfig = {
    storeDomain: 'https://velroq-dev.myshopify.com',
    storefrontToken: 'b6691d0a704501a9315a6f954f34e925',
    storefrontApiVersion: '2024-04',
};

const prodShopifyStorefrontClientConfig: ShopifyStorefrontClientConfig = {
    storeDomain: 'https://velroq.myshopify.com',
    storefrontToken: 'c02cea3a11946eed4f9165fed1053a46',
    storefrontApiVersion: '2024-04',
};

export interface GetShopifyStorefrontClientConfigProps {
    environment?: Environment;
}

export function getShopifyStorefrontClientConfig({
    environment = Environment.DEV,
}: GetShopifyStorefrontClientConfigProps): ShopifyStorefrontClientConfig {
    switch (environment) {
        case Environment.PROD:
            return prodShopifyStorefrontClientConfig;
        case Environment.TEST:
            return testShopifyStorefrontClientConfig;
        case Environment.DEV:
            return devShopifyStorefrontClientConfig;
    }
}

// Admin config
const getToken = (): string => {
    const token = process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN;

    if (!token) {
        // eslint-disable-next-line no-console
        console.error(
            'Could not find SHOPIFY_ADMIN_API_ACCESS_TOKEN in process.env. Please make sure it is available. Note that recently, SHOPIFY_API_ACCESS_TOKEN is renamed to SHOPIFY_ADMIN_API_ACCESS_TOKEN to better indicate to what API this key gives access.',
        );
        return '';
    }

    return token;
};

const getPrivateToken = (): string => {
    const token = process.env.SHOPIFY_DELEGATE_ACCESS_TOKEN;

    if (!token) {
        // eslint-disable-next-line no-console
        console.error('Could not find SHOPIFY_DELEGATE_ACCESS_TOKEN in process.env.');
        return '';
    }

    return token;
};

export function createShopifyAdminClientConfig() {
    return {
        domain: storeDomain(getCurrentEnv() !== Environment.PROD),
        accessToken: getToken(),
        privateToken: getPrivateToken(),
        apiVersion: '2024-04',
    };
}

export function getAdminApiRequestHeaders(buyerIp?: string | null) {
    return {
        'X-Shopify-Access-Token': getToken(),
        'X-GraphQL-Cost-Include-Fields': 'true',
        'Content-Type': 'application/json',
        'Shopify-Storefront-Private-Token': getPrivateToken(),
        'Shopify-Storefront-Buyer-IP': buyerIp ?? '',
    };
}
