'use client';

import i18n from 'i18next';
import { useMemo } from 'react';

import { useLocalisation } from './useLocalisation';

export function useTranslation(namespaces?: string[]) {
    const { language } = useLocalisation();

    const instance = useMemo(() => {
        return i18n.cloneInstance({ lng: language ?? undefined });
    }, [language]);

    return {
        t: (key: string, defaultTranslation?: string, options?: Record<string, string | undefined>) =>
            i18n.t(key, defaultTranslation, {
                lng: language ?? undefined,
                ns: options?.ns ?? namespaces,
                ...options,
            }),
        i18n: instance,
    };
}
