import categoriesDE from '../locales/de/translation-categories.json';
import translationDE from '../locales/de/translation.json';
import categoriesEN from '../locales/en/translation-categories.json';
import translationEN from '../locales/en/translation.json';
import categoriesFI from '../locales/fi/translation-categories.json';
import translationFI from '../locales/fi/translation.json';
import categoriesFR from '../locales/fr/translation-categories.json';
import translationFR from '../locales/fr/translation.json';
import categoriesNO from '../locales/no/translation-categories.json';
import translationNO from '../locales/no/translation.json';
import categoriesSV from '../locales/sv/translation-categories.json';
import translationSV from '../locales/sv/translation.json';
import { isInArray } from '../utils/TypeUtils';

export const SUPPORTED_LANGUAGES = ['en', 'sv', 'fi', 'de', 'fr', 'no'] as const;
export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];
export const isSupportedLanguage = (input: string) => isInArray(input, SUPPORTED_LANGUAGES);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResourcesStructure = Record<SupportedLanguage, { translation: any; categories: any }>;
export const resources: ResourcesStructure = {
    en: {
        translation: translationEN,
        categories: categoriesEN,
    },
    sv: {
        translation: translationSV,
        categories: categoriesSV,
    },
    fi: {
        translation: translationFI,
        categories: categoriesFI,
    },
    de: {
        translation: translationDE,
        categories: categoriesDE,
    },
    fr: {
        translation: translationFR,
        categories: categoriesFR,
    },
    no: {
        translation: translationNO,
        categories: categoriesNO,
    },
};

export const i18nOptions = {
    fallbackLng: 'en',
    debug: false,
    supportedLanguages: SUPPORTED_LANGUAGES,
    load: 'languageOnly',
    resources,

    detection: {
        order: ['cookie'],
        lookupCookie: 'language',
        lookupFromPathIndex: 0,
        caches: ['cookie'],
        cookieMinutes: 999999999,
        cookieOptions: { path: '/', sameSite: 'strict' },
    },

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
};
