import { ShippableCountry } from '../constants/countries';

export interface VatRegexParams {
    r: string;
    minLength?: number;
    maxLength?: number;
    exactLength?: number | number[];
    mustInclude?: string | number;
}

export const countryVatRegex: Record<ShippableCountry, VatRegexParams> = {
    AT: { r: 'U[0-9]{8}', exactLength: 9, mustInclude: 'U' },
    BE: { r: '[0-9]{10}', exactLength: 10 },
    BG: { r: '[0-9]{9,10}', minLength: 9, maxLength: 10 },
    CY: { r: '[0-9]{8}[A-Z]', exactLength: 9, mustInclude: 1 },
    CZ: { r: '[0-9]{8,10}', minLength: 8, maxLength: 10 },
    DE: { r: '[0-9]{9}', exactLength: 9 },
    DK: { r: '[0-9]{8}', exactLength: 8 },
    EE: { r: '[0-9]{9}', exactLength: 9 },
    ES: { r: '[0-9A-Z][0-9]{7}[0-9A-Z]', exactLength: 9 },
    FI: { r: '[0-9]{8}', exactLength: 8 },
    FR: { r: '[0-9A-Z]{2}[0-9]{9}', exactLength: 11 },
    GB: { r: '[0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}', exactLength: [5, 9, 12] },
    GR: { r: '[0-9]{9}', exactLength: 9 },
    HU: { r: '[0-9]{8}', exactLength: 8 },
    IE: {
        r: '([0-9][A-Z][0-9]{5,6}[A-Z])|([0-9]{7,8}[A-Z])|([0-9]{6,7}[A-Z]{2})',
        minLength: 8,
        maxLength: 9,
        mustInclude: 1,
    },
    IT: { r: '[0-9]{11}', exactLength: 11 },
    LT: { r: '([0-9]{9}|[0-9]{12})', exactLength: [9, 12] },
    LU: { r: '[0-9]{8}', exactLength: 8 },
    LV: { r: '[0-9]{11}', exactLength: 11 },
    MT: { r: '[0-9]{8}', exactLength: 8 },
    NL: { r: '[0-9]{9}B[0-9]{2}', exactLength: 12, mustInclude: 'B' },
    PL: { r: '[0-9]{10}', exactLength: 10 },
    PT: { r: '[0-9]{9}', exactLength: 9 },
    SE: { r: '[0-9]{12}', exactLength: 12 },
    SI: { r: '[0-9]{8}', exactLength: 8 },
    SK: { r: '[0-9]{10}', exactLength: 10 },
    HR: { r: '[0-9]{11}', exactLength: 11 },
    NO: { r: '[0-9]{9}', exactLength: 9 },
    RO: { r: '[0-9]{2,10}', minLength: 2, maxLength: 10 },
    AL: { r: '[JKLM][0-9]{8}[A-Z]', exactLength: 10 },
    BA: { r: '[0-9]{12,13}', minLength: 12, maxLength: 13 },
    CA: { r: '[0-9]{9}[A-Z]{2}[0-9]{4}', exactLength: 15 },
    IS: { r: '[0-9]{5,6}', minLength: 5, maxLength: 6 },
    ME: { r: '[0-9]{8}', exactLength: 8 },
    MK: { r: '[0-9]{13}', exactLength: 13 },
    RS: { r: '[0-9]{9}', exactLength: 9 },
    // CL: { r: '[0-9K]{8,9}', minLength: 8, maxLength: 9 },
    // TR: { r: '[0-9]{10}', exactLength: 10 },
};

export const vatRegex = (countryCode: ShippableCountry) => `^(${countryCode})?(${countryVatRegex[countryCode].r})$`;

export interface FormatValidVatNumberProps {
    vatNumber: string;
    countryCode?: ShippableCountry;
}

export function formatValidVatNumber({ vatNumber, countryCode }: FormatValidVatNumberProps) {
    let formatted = vatNumber.toUpperCase().replaceAll(' ', '');
    if (countryCode) {
        const groups = formatted.match(new RegExp(vatRegex(countryCode)));
        if (groups && groups.length >= 3) {
            formatted = groups[2];
        }
    }
    return `${countryCode}${formatted}`;
}
