import {
    AL,
    AT,
    BA,
    BE,
    BG,
    CA,
    CY,
    CZ,
    DE,
    DK,
    EE,
    ES,
    FI,
    FR,
    GB,
    GR,
    HR,
    HU,
    IE,
    IS,
    IT,
    LT,
    LU,
    LV,
    ME,
    MK,
    MT,
    NL,
    NO,
    PL,
    PT,
    RO,
    RS,
    SE,
    SI,
    SK,
} from 'country-flag-icons/react/3x2';
import { registerLocale } from 'i18n-iso-countries';
import * as de from 'i18n-iso-countries/langs/de.json';
import * as en from 'i18n-iso-countries/langs/en.json';
import * as fi from 'i18n-iso-countries/langs/fi.json';
import * as fr from 'i18n-iso-countries/langs/fr.json';
import * as no from 'i18n-iso-countries/langs/no.json';
import * as sv from 'i18n-iso-countries/langs/sv.json';
import { ReactNode } from 'react';

import { SUPPORTED_LANGUAGES, SupportedLanguage } from '../config/i18n.config';

registerLocale(en);
registerLocale(sv);
registerLocale(fi);
registerLocale(de);
registerLocale(fr);
registerLocale(no);

export const EU_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
] as const;

export type EuCountry = (typeof EU_COUNTRIES)[number];

export const SHIPPABLE_COUNTRIES = [
    ...EU_COUNTRIES,
    'GB',
    'NO',
    'AL',
    'BA',
    'CA',
    'IS',
    'ME',
    'MK',
    'RS',
    // 'CL',
    // 'TR'
] as const;

export type ShippableCountry = (typeof SHIPPABLE_COUNTRIES)[number];

export const EU_COUNTRY_NAMES: Record<EuCountry, string> = {
    AT: 'Austria',
    BE: 'Belgium',
    BG: 'Bulgaria',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    EE: 'Estonia',
    FI: 'Finland',
    FR: 'France',
    DE: 'Germany',
    GR: 'Greece',
    HU: 'Hungary',
    IE: 'Ireland',
    IT: 'Italy',
    LV: 'Latvia',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MT: 'Malta',
    NL: 'Netherlands',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ES: 'Spain',
    SE: 'Sweden',
} as const;

/* This constant is used to determine for which countries the shipping popup should NOT be displayed. */
export const SHIPPABLE_COUNTRY_NAMES: Record<ShippableCountry, string> = {
    ...EU_COUNTRY_NAMES,
    GB: 'United Kingdom',
    NO: 'Norway',
    AL: 'Albania',
    BA: 'Bosnia and Herzegovina',
    CA: 'Canada',
    IS: 'Iceland',
    ME: 'Montenegro',
    MK: 'North Macedonia',
    RS: 'Serbia',
    // CL: 'Chile',
    // TR: 'Turkey',
} as const;

export const EU_COUNTRY_FLAGS: Record<EuCountry, ReactNode> = {
    AT: <AT className="h-5 rounded-sm" />,
    BE: <BE className="h-5 rounded-sm" />,
    BG: <BG className="h-5 rounded-sm" />,
    HR: <HR className="h-5 rounded-sm" />,
    CY: <CY className="h-5 rounded-sm" />,
    CZ: <CZ className="h-5 rounded-sm" />,
    DK: <DK className="h-5 rounded-sm" />,
    EE: <EE className="h-5 rounded-sm" />,
    FI: <FI className="h-5 rounded-sm" />,
    FR: <FR className="h-5 rounded-sm" />,
    DE: <DE className="h-5 rounded-sm" />,
    GR: <GR className="h-5 rounded-sm" />,
    HU: <HU className="h-5 rounded-sm" />,
    IE: <IE className="h-5 rounded-sm" />,
    IT: <IT className="h-5 rounded-sm" />,
    LV: <LV className="h-5 rounded-sm" />,
    LT: <LT className="h-5 rounded-sm" />,
    LU: <LU className="h-5 rounded-sm" />,
    MT: <MT className="h-5 rounded-sm" />,
    NL: <NL className="h-5 rounded-sm" />,
    PL: <PL className="h-5 rounded-sm" />,
    PT: <PT className="h-5 rounded-sm" />,
    RO: <RO className="h-5 rounded-sm" />,
    SK: <SK className="h-5 rounded-sm" />,
    SI: <SI className="h-5 rounded-sm" />,
    ES: <ES className="h-5 rounded-sm" />,
    SE: <SE className="h-5 rounded-sm" />,
};

export const SHIPPABLE_COUNTRY_FLAGS: Record<ShippableCountry, ReactNode> = {
    ...EU_COUNTRY_FLAGS,
    GB: <GB className="h-5 rounded-sm" />,
    NO: <NO className="h-5 rounded-sm" />,
    AL: <AL className="h-5 rounded-sm" />,
    BA: <BA className="h-5 rounded-sm" />,
    CA: <CA className="h-5 rounded-sm" />,
    IS: <IS className="h-5 rounded-sm" />,
    ME: <ME className="h-5 rounded-sm" />,
    MK: <MK className="h-5 rounded-sm" />,
    RS: <RS className="h-5 rounded-sm" />,
    // CL: <CL className="h-5 rounded-sm" />,
    // TR: <TR className="h-5 rounded-sm" />,
};

export const LOCALISATION_PREFIXES = SUPPORTED_LANGUAGES.reduce<string[]>((prefixes, language) => {
    return [
        ...prefixes,
        ...SHIPPABLE_COUNTRIES.reduce<string[]>((prefixes, country) => {
            return [...prefixes, `/${language}/${country}/`];
        }, []),
    ];
}, []);

export const VAT_EXAMPLE: Record<ShippableCountry, string> = {
    AT: 'U 12345678',
    BE: '0123456789',
    BG: '123456789',
    HR: '12345678901',
    CY: '12345678 X',
    CZ: '12345678',
    DK: '12345678',
    EE: '123456789',
    FI: '12345678',
    FR: 'XX 123456789',
    DE: '123456789',
    GB: '123456789',
    GR: '123456789',
    HU: '12345678',
    IE: '1234567 XX',
    IT: '12345678901',
    LV: '12345678901',
    LT: '123456789012',
    LU: '12345678',
    MT: '12345678',
    NL: '123456789 B01',
    PL: '1234567890',
    PT: '123456789',
    RO: '1234567890',
    SK: '1234567890',
    SI: '12345678',
    ES: 'X 1234567 X',
    SE: '123456789012',
    NO: '123456789',
    AL: 'J12345678B',
    BA: '123456789012',
    CA: '123456789AB1234',
    IS: '12345',
    ME: '12345678',
    MK: '1234567890123',
    RS: '123456789',
    // CL: '12345678',
};

export const ZIP_EXAMPLE: Record<ShippableCountry, string> = {
    NL: '1234 AA',
    AT: '1234',
    BE: '1234',
    BG: '1234',
    HR: '12345',
    CY: '1234',
    CZ: '723 45',
    DK: '1234',
    EE: '12345',
    FI: '12345',
    FR: '12345',
    DE: '1234',
    GR: '123 45',
    HU: '1234',
    IE: 'A12 D5F7',
    IT: '12345',
    LV: 'LV-1234',
    LT: 'LT-12345',
    LU: '1234',
    MT: 'AAA 1234',
    PL: '12-345',
    PT: '1234-567',
    RO: '12345',
    SK: '82345',
    SI: 'SI-1234',
    ES: '12345',
    SE: '12345',
    GB: 'AB34 5FG',
    NO: '1234',
    AL: '1234',
    BA: '12345',
    CA: 'A0A 0A0',
    IS: '123',
    ME: '12345',
    MK: '1234',
    RS: '12345',
    // CL: '1234567',
    // TR: '12345',
};

export const CURRENCY_LIST: Record<ShippableCountry, string> = {
    AT: '€ EUR',
    BE: '€ EUR',
    BG: 'BGN',
    HR: '€ EUR',
    CY: '€ EUR',
    CZ: 'CZK',
    DK: 'DKK',
    EE: '€ EUR',
    FI: '€ EUR',
    FR: '€ EUR',
    DE: '€ EUR',
    GB: '£ GBP',
    GR: '€ EUR',
    HU: 'HUF',
    IE: '€ EUR',
    IT: '€ EUR',
    LV: '€ EUR',
    LT: '€ EUR',
    LU: '€ EUR',
    MT: '€ EUR',
    NL: '€ EUR',
    PL: 'PLN',
    PT: '€ EUR',
    RO: 'RON',
    SK: '€ EUR',
    SI: '€ EUR',
    ES: '€ EUR',
    SE: 'SEK',
    NO: 'NOK',
    AL: 'ALL',
    BA: 'BAM',
    CA: '$ CAD',
    IS: 'ISK',
    ME: '€ EUR',
    MK: 'MKD',
    RS: 'RSD',
    // CL: '1234567',
    // TR: '12345',
};

export const LOCALE_NAME: Record<SupportedLanguage, string> = {
    en: 'English',
    sv: 'Svensk',
    fi: 'Suomi',
    no: 'Norsk',
    de: 'Deutsch',
    fr: 'Français',
};

export function getCountryCodeByName(countryRecord: Record<ShippableCountry, string>, countryName: string) {
    for (const [countryCode, name] of Object.entries(countryRecord)) {
        if (name === countryName) {
            return countryCode;
        }
    }
    return undefined;
}
