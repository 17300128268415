export enum LocalStorageKey {
    CHECKOUT = 'checkout',
    DISCOUNT_CODE = 'discountCode',
    CHECKOUT_ID = 'checkoutId',
    SHOPIFY_CART_ID = 'shopifyCartId',
    USER_INFORMATION = 'userInformation',
    SHIPPING_ADDRESS = 'shippingAddress',
    BILLING_ADDRESS = 'billingAddress',
    NOTE = 'note',
}
